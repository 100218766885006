<template>
	<w-layout fill-height row>
		<w-flex>
			<w-layout row wrap>
				<w-flex sm6 xs12>
					<w-layout column ma-5>
						<w-flex>
							<w-text-input v-model="holdingUser.firstname" :label="$t('holding.admin-team.firstname')" disabled></w-text-input>
						</w-flex>
						<w-flex>
							<w-text-input v-model="holdingUser.lastname" :label="$t('holding.admin-team.lastname')" disabled></w-text-input>
						</w-flex>
						<w-flex>
							<w-text-input v-model="holdingUser.email" :label="$t('holding.admin-team.email')" disabled></w-text-input>
						</w-flex>
						<w-flex mb-2>
							<w-switch 
								v-model="holdingUser.is_holding_admin"
								:disabled="currentUser.id == holdingUser.id"
								:hide-details="currentUser.id == holdingUser.id"
								:hint="$t('holding.admin-team.welyb_admin_access_info')"
								:label="$t('holding.admin-team.administrator_welyb_info')"
								persistent-hint
								@input="updateUserRole(holdingUser)"
							/>
						</w-flex>
					</w-layout>
				</w-flex>
				<w-flex sm6 xs12>
					<w-layout align-center column ma-5>
						<w-flex mt-2 v-text="$t('holding.admin-team.account_created_on', { date: formattedDate })"></w-flex>
						<w-flex mt-2 mb-2>
							<w-btn v-if="holdingUser.first_use_at" @click="resetPassword()">{{ $t('holding.admin-team.reset_password') }}</w-btn>
						</w-flex>
						<w-flex mt-2 mb-2>
							<w-btn v-if="!holdingUser.first_use_at" :disabled="currentUser.id == holdingUser.id" @click="sendMail(holdingUser)">
								<span v-if="!holdingUser.invited_at">{{ $t('holding.admin-team.invit') }}</span>
								<span v-if="!holdingUser.did_connect && holdingUser.invited_at">{{ $t('holding.admin-team.reinvit') }}</span>
							</w-btn>
						</w-flex>
					</w-layout>
				</w-flex>
			</w-layout>
		</w-flex>
	</w-layout>
</template>


<script>
import HoldingUserModuleGuard from '@/mixins/ModulesGuards/Holding/HoldingUserModuleGuard'
export default {
	name: 'HoldingUserGeneralInformations',
	components: {},
	mixins: [HoldingUserModuleGuard],
	inject: ['holdingContext'],
	props: {},
	data: function () {
		return {
			pageLoading: true,
			user: null,
			teamListPage: {route: 'holding-team'}
		}
	},
	computed: {
		holdingId: function () {
			return this.holdingContext?.holding_id
		},
		userId: function () {
			return this.$route.params.userId
		},
		formattedDate: function () {
			return this.$d(new Date(this.holdingUser.created_at), 'regular')
		}
	},
	watch: {},
	created: function () {
	},
	mounted: function () {},
	destroyed: function () {
	},
	methods: {
		loadUserInformations: function () {
			this.service.findHoldingUser(this.holdingId, this.userId, { show_error: false }).then((user) => {
				this.user = user
				this.pageLoading = false
			}).catch(() => {
				this.goToPage(this.teamListPage)
			})
		},
		updateUserRole: function (user) {
			const data = {role_key: user.is_holding_admin ? 'admin' : 'collaborator'}
			this.service.updateHoldingUser(this.holdingId, user.id, data).then(() => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('holding.admin-team.role_change_success'))	
			})
		},
			resetPassword: function () {
			this.$dialog
				.warning({
					text: this.$t('holding.admin-team.actions.reset_password', { username: this.holdingUser.username }),
					title: this.$t('holding.admin-team.reset_password'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then((res) => {
					if (res) {
						this.service.resetPassword(this.holdingId, this.holdingUser.id).then(() => {
							this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('holding.admin-team.reset_password_done'))
						})
					}
				})
		},
		sendMail: function (user) {
			let { text: text, title: title } = this.getTextAndTitle(user)
			this.$dialog
				.warning({
					text: text,
					title: title,
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res && (!user.invited_at || (!user.did_connect && user.invited_at))) {
							this.service.sendMail(this.holdingId, user.id).then(() => {
								this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('actions.mail_sent'))					
							})
						}
					}
				)
		},
		getTextAndTitle: function (user) {
			let text = ''
			let title = ''
			if (!user.invited_at) {
				text = this.$t('customers.actions.are_you_sure_send_mail_invitation', { email: user.email })
				title = this.$t('customers.actions.are_you_sure_title_mail_invitation', { name: user.username })
			} else if (!user.did_connect && user.invited_at) {
				text = this.$t('customers.actions.are_you_sure_send_mail_reinvitation', { email: user.email })
				title = this.$t('customers.actions.are_you_sure_title_mail_reinvitation', { name: user.username })
			}
			return { text: text, title: title }
		},

		goToPage: function (page) {
            const currentParams = this.$route.params
            this.appService.goTo({
                name: page.route,
                params: currentParams
            })
        }
	}
}
</script>
